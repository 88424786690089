import styled from '@emotion/styled';
import { green, purple, blue, orange, red, pink, teal, brown, blueGrey, lime } from '@mui/material/colors';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import _ from 'lodash';
import React, { MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ScatterChart,
  Scatter,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { useRecoilState } from 'recoil';
import { ChartType, Feature, Operation, } from '../../models/feature';
import { hoveredFeatureState, selectedFeatureIdsState } from '../../recoils/feature';
import { estimateTickWidth } from '../../utils/ticks';

import OperationIcon from '../common/OperationIcon';
import {ReactComponent as BarChartIconSvg} from '../../assets/bar-chart-icon.svg';
import {ReactComponent as MenuIconSvg} from '../../assets/menu-icon.svg';
import useDynamicYAxisWidth from '../../utils/recharts';
import { FeatureOperation, useFeatureManager } from '../../utils/hooks/useFeatureManager';


const ElementWrapper = styled.div`
position: relative;
width: 100%;
height: 100%;

opacity: 0;

transition: opacity .3s;

&.created {
  opacity: 1;
}
`;

const Element = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
padding: 0px;

font-size: 14px;

border-radius: 4px;

background-color: #ffffff;

z-index: 6;
`;

const ElementShadow = styled.div<{color?: string}>`
position: absolute;

top: 0px;
left: 0px;

width: 100%;
height: 100%;

border-radius: 4px;
box-shadow: 0px 1px 5px rgba(0,0,0,.5);
background-color: #ffffff;

opacity: 0.8;

z-index: 5;

transition: opacity .15s, transform .15s, background-color .15s;

*:hover > &,
*.reader > &,
*.selected > & {
  opacity: 1;
  background-color: #bbbbbb;
  transform: scale(1.02679, 1.02449);
}
*.selected > & {
  background-color: ${green[600]};
}
*.reader > & {
  background-color: ${({color}) => color || green[600]};
}
`;

// 224 x 245
const ElementChildRepresentative = styled.div<{color?: string}>`
position: absolute;

top: 3px;
left: 3px;

width: 100%;
height: 100%;

border-radius: 4px;
background-color: #ffffff;

transition: background-color .15s;

z-index: 4;

*:hover > & {
  background-color: #bbbbbb;
}
*.selected > & {
  background-color: ${green[600]};
}
*.reader > & {
  background-color: ${({color}) => color || green[600]};
}
`;

const ElementChildRepresentativeShadow = styled(ElementShadow)`
top: 3px;
left: 3px;
z-index: 3;
`;

const ElementChildRepresentative2Depth = styled(ElementChildRepresentative)`
top: 6px;
left: 6px;
z-index: 2;
`
const ElementChildRepresentative2DepthShadow = styled(ElementShadow)`
top: 6px;
left: 6px;
z-index: 1;
`;

const ChartCheckBox = styled.div`
position: absolute;
top: 2px;
left: 2px;

width: 0px;
height: 0px;

padding: 0px;
margin: 0px;

font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: #ffffff;

opacity: 0;

border: 2px solid #bbbbbb;
border-radius: 12px;

background-color: #ffffff;

transition: all .15s;

z-index: 10;

*:hover > &,
.selected > * > & {
  top: -10px;
  left: -10px;
  width: 24px;
  height: 24px;
  opacity: 1;
}

.selected > * > & {
  border-color: ${green[600]};
  background-color: ${green[600]};
}
`;

const CardDescriptionSection = styled.div<{bgColor?: string}>`
display: flex;
flex-direction: column;

width: 100%;
flex: 1 0 0px;
padding: 6px 8px;
border-top: 1px solid #dddddd;

.reader > * > & {
  background-color: ${({bgColor}) => bgColor || 'transparent'}
}
`;

const ChartTitleWrapper = styled.div`
display: flex;
width: 100%;
height: 24px;
`;

const ChartTitle = styled.div`
flex: 1 0 0px;
font-size: 15px;
font-weight: 500;
line-height: 24px;
color: #333333;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

const ChartTypeChangeButton = styled.button`
flex: 0 0 24px;
width: 24px;
height: 24px;

padding: 0px;

border: none;
border-radius: 24px;

background-color: #ffffff;
cursor: pointer;

&:hover {
  background-color: #eeeeee;
}
`;

const ChartMenuButton = styled.button`
flex: 0 0 24px;
width: 24px;
height: 24px;

padding: 0px;

border: none;
border-radius: 24px;

background-color: #ffffff;
cursor: pointer;

&:hover, &.opened {
  background-color: #eeeeee;
}
`;

const ChartMenuItem = styled(MenuItem)`
font-size: 14px;
`

const ChartDescriptionWrapper = styled.div`
width: 100%;
height: 16px;

font-size: 12px;
font-weight: 400;
line-height: 16px;
color: rgba(0, 0, 0, .6);

overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const ChartNoDescription = styled.div`
color: rgba(0, 0, 0, .4);
`

const ChartOperationWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
height: 28px;
`;

const ChartOperationBadge = styled.div`
position: relative;

width: 16px;
height: 16px;
padding: 1px;
margin: 0px 8px 0px 0px;

border-radius: 2px;
background-color: #bbbbbb;

& path: #ffffff !important;

&::before {
  position: absolute;
  display: block;
  content: '';

  top: 7px;
  left: 7px;

  width: 2px;
  height: 2px;
  
  border-radius: 2px;
  background-color: #ffffff;
}

.active > & {
  background-color: #107CC7;
}
.active > &::before {
  display: none;
}
`;

const ChartOperandDescription = styled.div`
flex: 1 0 0px;

font-size: 12px;
font-weight: 400;
line-height: 16px;
color: rgba(0, 0, 0, .4);

overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;

.active > & {
  color: rgba(0, 0, 0, .8);
}
`;

const ChartContainer = styled.div`
flex: 0 0 180px;
width: 100%;

border-radius: 4px 4px 0px 0px;

.selected > * > & {
  background-color: #EDF6ED;
}
`;


const colorList = [
  purple[300], blue[400], green[400], orange[400], red[300],
  pink[300], brown[200], teal[300], blueGrey[400], lime[500]
]

interface MemoizedChartProps {
  feature: Feature,
  fontSize?: number,
  width?: number,
  height?: number,
  yAxisWidth?: number,
}

const shouldMaintainMemoizedChart = (
  beforeProps: MemoizedChartProps,
  nextProps: MemoizedChartProps,
): boolean => {
  return _.isEqual(_.omit(beforeProps.feature.chart, 'gridInfo'),
                   _.omit(nextProps.feature.chart, 'gridInfo'));
}

const MemoizedChart = memo(({
  feature,
  fontSize = 10,
  width = 224,
  height = 180,
}: MemoizedChartProps) => {

  const index2Cleansed = _.map(feature.chart.ticks, (tick) => {
    return _.trim(tick.split('(')[0])
  })
  const cleansed2Tick = _.reduce(feature.chart.ticks, (
    output: any, tick: string, index: number
  ) => {
    output[index2Cleansed[index]] = tick
    return output
  }, {})

  const data = _.map(_.zip(...feature.chart.data), (dataPair, tickIdx) => {
    const datum: any = {
      name: index2Cleansed[tickIdx],
    };
    _.each(dataPair, (value, labelIdx) => {
      datum[feature.chart.labels[labelIdx]] = value;
    });
    return datum;
  });
  const { yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 10 + 8,
  });

  const domainPerAxes = _.reduce(feature.chart.data, (output: any, datum, labelIdx) => {
    const axes = feature.chart.yAxes[labelIdx]
    const hasPositive = _.some(_.map(datum, (d) => d > 0))
    const hasNegative = _.some(_.map(datum, (d) => d < 0))

    output[axes][0] = output[axes][0] || hasNegative
    output[axes][1] = output[axes][1] || hasPositive
    return output
  }, {'left': [false, false], 'right': [false, false]})

  const domainToAxisDomain = (
    domain: [boolean, boolean]
  ): [number|string, number|string] => {

    const [hasNegative, hasPositive] = domain
    return [
      hasNegative ? 'auto' : 0,
      hasPositive ? 'auto' : 0,
    ]
  }

  const yAxisTypes = _.uniq(feature.chart.yAxes)
  const yAxisDomainFunctions: [number|string, number|string] = [
    0,
    0
  ]

  const exponentialTickFormatter = (value: any, index: number): string => {
    if (_.isFinite(value)) {
      const msb = value > 0
      const absValue = Math.abs(value)
      if (absValue > 10000) {
        const digit = _.floor(Math.log10(absValue))
        const quot = _.round(absValue / (10**digit), 1)
        return `${msb ? '' : '-'}${quot}e+${digit}`
      }
    }
    return value
  }

  const chartMargin = {
    top: 12,
    right: 12,
    left: 0,
    bottom: 0,
  }

  const toolTipLabelFormatter = (label: any, payload: any) => {
    return cleansed2Tick[label]
  }
  const toolTipValueFormatter = (value: any, name: any, props: any) => {
    return value.toLocaleString()
  }
  const toolTipParameter = {
    wrapperStyle: {
      zIndex: 999,
      pointerEvents: 'unset' as any,
      outline: 'none',
    },
    contentStyle: {
      padding: '8px'
    },
    itemStyle: {
      fontSize: '12px',
      padding: '2px 0px'
    },
    labelStyle: {
      fontSize: '14px'
    },
    labelFormatter: toolTipLabelFormatter,
    formatter: toolTipValueFormatter,
  }

  const yAxisLabelStyle = {
    fontSize: "10px",
    transform: "rotate(180deg) translateX(-12px)",
    transformOrigin: "center left",
  };


  if (feature.chart.type === ChartType.SCATTER) {
    const labels = feature.chart.labels
    return (
      <ScatterChart width={width}
                    height={height}
                    margin={chartMargin}
                    ref={setChartRef}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number"
               dataKey={labels[0]}
               name={labels[0]}
               tick={{fontSize: 10}}
               tickFormatter={exponentialTickFormatter}>
          <Label
            position="insideBottom"
            style={{fontSize: "10px"}}
          >{labels[0]}</Label>
        </XAxis>
        <YAxis type="number"
               dataKey={labels[1]}
               name={labels[1]}
               width={yAxisWidth}
               tick={{fontSize: 10}}
               tickFormatter={exponentialTickFormatter}>
               <Label
                 position="insideLeft"
                 style={{
                  ...yAxisLabelStyle,
                  writingMode: 'vertical-lr',
                  textAnchor: 'middle',
                }}
               >{labels[1]}</Label>
        </YAxis>
        <ZAxis type="number" range={[13, 14]} />
        <Scatter data={data}
                 fill={colorList[0]}
                 isAnimationActive={false} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }}
                 {...toolTipParameter}/>
      </ScatterChart>
    )
  } else if (feature.chart.type === ChartType.BAR) {
    return (
      <BarChart width={width}
                height={height}
                data={data}
                barGap={2}
                margin={chartMargin}
                ref={setChartRef}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"
               tick={{fontSize}}
               tickFormatter={exponentialTickFormatter}>
          <Label
            position="insideBottom"
            style={{fontSize: "10px"}}
          >time</Label>
        </XAxis>
        {
          _.map(yAxisTypes, (yAxisType) => {
            return <YAxis width={yAxisWidth}
                          key={yAxisType}
                          yAxisId={yAxisType}
                          tick={{fontSize}}
                          orientation={yAxisType}
                          domain={yAxisDomainFunctions}
                          tickFormatter={exponentialTickFormatter}
                          tickSize={4}>
                          <Label
                            position="insideLeft"
                            style={{
                              ...yAxisLabelStyle,
                              writingMode: 'vertical-lr',
                              textAnchor: 'middle',
                            }}
                            >{feature.name}</Label>
                   </YAxis>
          })
        }
        <Tooltip {...toolTipParameter}/>
        {_.map(feature.chart.labels, (label, labelIdx) => {
          return <Bar type="monotone"
                        dataKey={label}
                        fill={colorList[labelIdx]}
                        yAxisId={feature.chart.yAxes[labelIdx]}
                        key={labelIdx}
                        isAnimationActive={false}
                  />
        })}
      </BarChart>
    )
  }

  return (
    <LineChart width={width}
               height={height}
               data={data}
               margin={chartMargin}
               ref={setChartRef}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name"
             tick={{fontSize}}
             tickFormatter={exponentialTickFormatter}>
        <Label
          position="insideBottom"
          style={{fontSize: "10px"}}
        >time</Label>
      </XAxis>
      {
        _.map(yAxisTypes, (yAxisType) => {
          return <YAxis width={yAxisWidth}
                        key={yAxisType}
                        yAxisId={yAxisType}
                        tick={{fontSize}}
                        orientation={yAxisType}
                        domain={domainToAxisDomain(domainPerAxes[yAxisType])}
                        tickFormatter={exponentialTickFormatter}
                        tickSize={4}>
                        <Label
                          position="insideLeft"
                          style={{
                            ...yAxisLabelStyle,
                            writingMode: 'vertical-lr',
                            textAnchor: 'middle',
                          }}
                          >{feature.name}</Label>
                 </YAxis>
        })
      }
      <Tooltip {...toolTipParameter} />
      {_.map(feature.chart.labels, (label, labelIdx) => {
        return <Line type="monotone"
                      dataKey={label}
                      stroke={colorList[labelIdx]}
                      yAxisId={feature.chart.yAxes[labelIdx]}
                      key={labelIdx}
                      isAnimationActive={false}
                      dot={false}
                />
      })}
    </LineChart>
  )
}, shouldMaintainMemoizedChart);

interface ChartProps {
  borderColor?: string
  bgColor?: string
  feature: Feature
  selectable: boolean
  isReaderMode?: boolean
  highlightWhenCreated?: boolean
  highlightWhenDrillDowned?: boolean
  onClick?: () => void
  updateFeature?: (newFeature: Feature) => void
}


const ChartElement = ({
  borderColor,
  bgColor,
  feature,
  selectable,
  isReaderMode = false,
  highlightWhenDrillDowned = false,
  onClick,
  updateFeature,
}: ChartProps) => {

  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isDrillDowned, setIsDrillDowned] = useState<boolean>(highlightWhenDrillDowned);

  const [
    selFeatIds,
    setSelFeatIds
  ] = useRecoilState<string[]>(selectedFeatureIdsState);

  const [
    hoveredFeature,
    setHoveredFeature
  ] = useRecoilState<Feature|undefined>(hoveredFeatureState);

  const [applyOperationToFeature] = useFeatureManager();

  if (highlightWhenDrillDowned) {
    useEffect(() => {
      setIsDrillDowned(true);
      setTimeout(() => setIsDrillDowned(false), 1000);
    }, []);

  }
  useEffect(() => {
    setTimeout(() => setIsCreated(true), 300);
  }, []);

  const onChartClick = (evt: MouseEvent) => {
    if (evt.ctrlKey) { toggleFeature(); }
    if (onClick) { onClick(); }
  };

  const toggleFeature = useCallback(() => {
    if (!selectable) return
    const oldSelFeatIds = _.cloneDeep(selFeatIds);
    if (_.includes(oldSelFeatIds, feature.id)) {
      return setSelFeatIds(_.without(oldSelFeatIds, feature.id));
    }
    oldSelFeatIds.push(feature.id);
    setSelFeatIds(oldSelFeatIds);
  }, [selFeatIds, setSelFeatIds]);

  const onMouseEnter = useCallback((feature: Feature) => {
    setHoveredFeature(feature);
  }, [hoveredFeature, setHoveredFeature]);

  const onMouseLeave = useCallback(() => {
    setHoveredFeature(undefined);
  }, [hoveredFeature, setHoveredFeature]);

  const operandIdx = _.indexOf(selFeatIds, feature.id);
  
  const hasChild = !_.isEmpty(feature.operands);
  const hasGrandChild = (_.isArray(feature.operands) &&
                         feature.operands.length > 0 &&
                         !_.isEmpty(feature.operands[0].operands));

  const switchChartType = () => {
    if (_.isUndefined(updateFeature)) return
    const newFeature = _.cloneDeep(feature)
    newFeature.chart.type = newFeature.chart.type === ChartType.BAR ? ChartType.LINE : ChartType.BAR
    updateFeature(newFeature)
  }

  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null)
  const ref = useRef<HTMLButtonElement>(null);

  const menuIsOpened = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const splitIt = useCallback((operand: Feature) => {
    applyOperationToFeature(FeatureOperation.SPLIT, [operand.id])
  }, [feature, applyOperationToFeature]);

  const deleteIt = useCallback((operand: Feature) => {
    applyOperationToFeature(FeatureOperation.DELETE, [operand.id])
  }, [feature, applyOperationToFeature]);

  return (
    <ElementWrapper
      className={[
        (operandIdx >= 0 && selectable) ? 'selected': '',
        isCreated ? 'created': '',
        ((isReaderMode && borderColor) ? 'reader' : '')
      ].join(' ')}
    >
      <Element
        className={[
          hoveredFeature && hoveredFeature.id === feature.id ? 'hovered' : '',
          isDrillDowned ? 'drilldowned': '',
        ].join(' ')}
        onMouseEnter={() => onMouseEnter(feature)}
        onMouseLeave={onMouseLeave}
        onClick={onChartClick}
      >
        {selectable && 
          <ChartCheckBox
            onClick={toggleFeature}
          >
            {operandIdx >= 0 ? String.fromCharCode(operandIdx + 65) : ''}
          </ChartCheckBox>
        }
        <ChartContainer>
          <MemoizedChart feature={feature}/>
        </ChartContainer>
        <CardDescriptionSection bgColor={bgColor}>
          <ChartTitleWrapper>
            <ChartTitle>
              {feature.name}
            </ChartTitle>
            {selectable && feature.chart.type !== ChartType.SCATTER &&
              <ChartTypeChangeButton onClick={switchChartType}>
                <BarChartIconSvg/>
              </ChartTypeChangeButton>
            }
            {
              selectable &&
              <React.Fragment>
                <ChartMenuButton className={menuIsOpened ? 'opened' : ''}
                                 onClick={handleClick}
                                 ref={ref}>
                  <MenuIconSvg/>
                </ChartMenuButton>
                <Menu open={menuIsOpened}
                      anchorEl={anchorEl}
                      onClose={handleClose}>
                  {/* <ChartMenuItem>Rename</ChartMenuItem> */}
                  {
                    feature.operands.length > 0 &&
                    <ChartMenuItem onClick={() => splitIt(feature)}>Split</ChartMenuItem>
                  }
                  <ChartMenuItem onClick={() => deleteIt(feature)}>Delete</ChartMenuItem>
                </Menu>
              </React.Fragment>
            }
          </ChartTitleWrapper>
          <ChartDescriptionWrapper>
            {feature.description || <ChartNoDescription>No description</ChartNoDescription>}
          </ChartDescriptionWrapper>
          <ChartOperationWrapper className={_.isUndefined(feature.operation) ? '' : 'active'}>
            <ChartOperationBadge>
              <OperationIcon operation={feature.operation}/>
            </ChartOperationBadge>
            <ChartOperandDescription>
              {
                _.isUndefined(feature.operands) ? 'No data' :
                _.join(_.map(feature.operands, 'name'), ', ') || 'No data'
              }
            </ChartOperandDescription>
          </ChartOperationWrapper>
        </CardDescriptionSection>
      </Element>
      <ElementShadow color={borderColor}/>
      {hasChild &&
        <React.Fragment>
          <ElementChildRepresentative
            color={borderColor}
            className={[isDrillDowned ? 'drilldowned': ''].join(' ')}
          />
          <ElementChildRepresentativeShadow color={borderColor}/>
        </React.Fragment>
      }
      {hasGrandChild &&
        <React.Fragment>
          <ElementChildRepresentative2Depth
            color={borderColor}
            className={[isDrillDowned ? 'drilldowned': ''].join(' ')}
          />
          <ElementChildRepresentative2DepthShadow color={borderColor}/>
        </React.Fragment>
      }
    </ElementWrapper>
  )
};

export default ChartElement;
export {MemoizedChart};
